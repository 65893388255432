import React from "react"
import Layout from "../layouts/de"
import Seo from "../components/seo"
import SimplePage from "../templates/simple-page"
import Features from "../components/features"

const DsgvoPage = () => {
    return (
        <Layout>
            <Seo id="dsgvo" />
            <SimplePage title="Datenschutzerklärung">
            
            <h2>Verarbeitung personenbezogener Daten von Geschäftspartnern</h2>
            <p>Als Geschäftspartner werden von Ihnen regelmäßig folgende personenbezogenen Daten ausschließlich zu geschäftlichen Zwecken verarbeitet:</p>

            <Features items={[
                "Name",
                "Vorname",
                "Firmenadresse",
                "Rechnungsadresse",
                "Lieferadresse",
                "Telefonnummer",
                "Faxnummer",
                "Mobilfunknummer (optional)",
                "E-Mail-Adresse",
                "Geschäftliche Bankverbindungen"
            ]} />

            <p>Alle Angaben sind zur Kontaktaufnahme und der ordentlichen Abwicklung von Angeboten und Aufträgen notwendig. Als Rechtsgrundlage dient Art. 6 (1) b) der EU-DS-GVO. Zur Meldung an öffentliche Stellen, wie z. B. die zuständigen Finanzbehörden oder das statistische Bundesamt gilt als Rechtsgrundlage zusätzlich Art. 6 (1) c) der EU-DS-GVO. Soweit Sie auf Basis einer Einwilligung der Datenverarbeitung zustimmen, gilt Art. 6 (1) a) der EU-DS-GVO. Sollten wir ein berechtigtes Interesse zur Datenverarbeitung anmelden, gilt Art. 6 (1) f) der EU-DS-GVO.</p>

            <p>Eine Weitergabe an Dritte findet nur in folgenden Fällen statt:</p>

            <Features items={[
                "EDI mit Eddyson, sofern Personendaten in Transaktionsdaten vorhanden sind."
            ]} />

            <p>Zur Frachtabwicklung müssen wir ggf. Informationen zum entsprechenden Ansprechpartner des Kunden (Vorname, Nachname, Telefonnummer) an den beauftragten Spediteur weiter geben.</p>
            <p>Des Weiteren sind wir gesetzlich verpflichtet auch Daten an behördliche Stellen, wie z.B. die Finanz- oder Zollämter, weiter zu geben.</p>
            <p>Eine Übermittlung in Drittländer findet nicht statt.</p>
            <p>Diese Stammdaten werden in unseren IT-Systemen gespeichert, solange die Geschäftsbeziehung besteht und eine gesetzliche Legitimation dies vorschreibt. Daten unserer Geschäftspartner werden nach der gesetzlichen Aufbewahrungsfrist, die sich aus der jeweiligen Datenverwendung ergibt, gelöscht. Sollte ein Kunde eine Löschung oder Sperrung verlangen, werden seine Daten unmittelbar gesperrt und nach Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht oder anonymisiert.</p>

            <h2>Informationen zum Namen und die Kontaktdaten des Verantwortlichen</h2>
            <p>
                Fritz Finkernagel Drahtwerk GmbH &amp; Co. KG<br />
                Westiger Straße 120-128<br />
                58762 Altena
            </p>

            <p>Vertreten durch:<br />
            Geschäftsführer: Fritz Uwe Finkernagel</p>

            <p>
                Telefon: +49 2352/ 703-0<br />
                Telefax: +49 2352/ 75447<br />
                E-Mail: <a href="mailto:info@finkernagel.com">info@finkernagel.com</a>
            </p>

            <h2>Informationen zum Namen und die Kontaktdaten des Datenschutzbeauftragten</h2>

            <p>
                Stefan Käsler<br />
                Goethestraße 75<br />
                58566 Kierspe
            </p>
            <p>Telefon: +49 2359 291420<br />
            E-Mail: <a href="mailto:finkernagel@complere.de">finkernagel@complere.de</a></p>

            <h2>Informationen zum Recht auf Auskunft</h2>
            <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten.</p>
            
            <h2>Informationen zum Recht auf Berichtigung /Löschung oder Einschränkung der Verarbeitung</h2>
            <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema „Personenbezogene Daten“ können Sie sich jederzeit unter der angegebenen Adresse an uns wenden.</p>
            
            <h2>Informationen zum Recht auf Widerspruch auf die Verarbeitung</h2>
            <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
            
            <h2>Informationen zum Recht auf Datenübertragbarkeit</h2>
            <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
            
            <h2>Informationen zum Recht auf Beschwerde bei der Aufsichtsbehörde</h2>
            <p>Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der nächstgelegenen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen für unser Unternehmen ist der Landesdatenschutzbeauftragte NRW.</p>


</SimplePage>
</Layout>
)
}

export default DsgvoPage
